<template>
  <div class="container">
    <div class="right_cont">
      <div class="title flex_center_start_box">
        <div class="title_txt flex_center"><img src="../../assets/img/detail/title_ic4.png" />HISTORY AND EVENTS</div>
      </div>
      <div class="his_box">
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.date" placement="top">{{ activity.events }}</el-timeline-item>
        </el-timeline>
      </div>
      <Page v-if="window.innerWidth>821" :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
    </div>
  </div>
</template>
<script>
import { getHistory } from '@/api/companyApi.js'
import Page from '@/components/page'
import crypto from '@/utils/crypto'
export default {
  components: { Page },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      activities: [],
      window:window
    }
  },
  computed: {},

  created() {
    this.getData()
  },
  methods: {
    getData() {
      const id3a = this.$route.query.id3a
      const companyCountry = this.$route.query.companyCountry
      // let params = 'id3a=	156107587326&companyCountry=CN'
      let params = 'id3a=	' + id3a + '&companyCountry=' + companyCountry
      getHistory(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          this.activities = data.data
          this.page.total = this.activities.length
        }
        console.log('result- gethistory', data)
      })
    },
    //分页
    onPageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getData()
    },
    currtentPageChange(pageSize) {
      this.page.pageSize = pageSize
      this.getData()
    },
  },
}
</script>
<style scoped>
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}
.right_cont {
  margin-top: 60px;
}
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pageBox {
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
  width: 100%;
  padding: 32px 30px 10px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
  display: flex;
  padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
  width: 8px;
  height: 8px;
  left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
  background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
  border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
  font-size: 16px;
  color: #8497ab;
  padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
  padding-bottom: 24px;
  font-size: 16px;
  color: #022955;
  border-bottom: 1px solid #e7eaed;
  margin-left: 20px;
  flex: 1;
  line-height: 26px;
  margin-top: -5px;
}
@media (max-width: 821px) {

.right_cont{
  margin-top: 20px !important;
}
.his_box /deep/ .el-timeline-item__content{
  font-size: 12px ;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
  font-size: 12px ;
}

}
</style>
